<template>
  <div class="gallery-container">
    <div class="thumbnails-grid">
      <div
          v-for="(image, index) in images"
          :key="index"
          class="thumbnail-item"
          @click="openLightbox(index)"
      >
        <img
            :src="image.src"
            :alt="image.caption || `Image ${index + 1}`"
            class="thumbnail-image"
        />
      </div>
    </div>

    <vue-easy-lightbox
        :visible="isLightboxVisible"
        :imgs="lightboxImages"
        :index="currentImageIndex"
        :moveDisabled="true"
        :showClose="true"
        :showButtons="true"
        :scrollDisabled="false"
        :rotateDisabled="true"
        :scaleDisabled="true"
        :fullscreenDisabled="true"
        :dragDisabled="true"
        @hide="closeLightbox"
    />
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed } from 'vue'
import VueEasyLightbox from 'vue-easy-lightbox'

const props = defineProps({
  images: {
    type: Array,
    required: true,
    default: () => []
  }
})


const lightboxImages = computed(() => {
  return props.images.map(img => img.src)
})

const isLightboxVisible = ref(false)
const currentImageIndex = ref(0)


const openLightbox = (index) => {
  currentImageIndex.value = index
  isLightboxVisible.value = true
}

const closeLightbox = () => {
  isLightboxVisible.value = false
}
</script>

<style scoped>
.gallery-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.thumbnails-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 20px;
}

.thumbnail-item {
  aspect-ratio: 1;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0px;
  transition: transform 0.5s ease;
}

.thumbnail-item:hover {
  transform: scale(1.02);
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .thumbnails-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .thumbnails-grid {
    grid-template-columns: 1fr;
  }
}

:deep(.vel-toolbar) {
  display: none !important;
}
</style>