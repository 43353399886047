<template>
  <div v-if="loading" class="loader-overlay">
    <div class="loader-wrapper">
      <img src="/img/loader.png" class="loader-image" />
      <div class="loader-circle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderComponent",
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 5000);
  },
};
</script>

<style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.2);
  z-index: 9999;
}

.loader-wrapper {
  position: relative;
  width: 200px;
  height: 200px;
}

.loader-image {
  width: 100%;
  height: 100%;
  border-radius: 2%; /* Zakulacení obrázku */
  object-fit: cover;
  z-index: 1;
  position: relative;
  left: 5px;
  top: 7px;
}

.loader-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  animation: rotateLoader 1s linear infinite;
}

@keyframes rotateLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
