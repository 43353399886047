
<template>
  <section class="gallery-section" id="gallery">
    <div class="side-text">
      <p>{{ $t('gallery') }}</p>
    </div>
        <image-gallery :images="galleryImages" />
  </section>
</template>
<script setup>
import ImageGallery from '../utils/ImageGallery.vue'

const galleryImages = [
  {
    src: '/img/gallery/1.JPG',
    caption: 'Premiéra promítání filmu Mistr Houslař'
  },
  {
    src: '/img/gallery/2.JPG',
    caption: 'Premiéra promítání filmu Mistr Houslař'
  },
  {
    src: '/img/gallery/3.JPG',
    caption: 'Premiéra promítání filmu Mistr Houslař'
  },
  {
    src: '/img/gallery/4.JPG',
    caption: 'Premiéra promítání filmu Mistr Houslař'
  },
  {
    src: '/img/gallery/5.JPG',
    caption: 'Premiéra promítání filmu Mistr Houslař'
  },
  {
    src: '/img/gallery/6.JPG',
    caption: 'Premiéra promítání filmu Mistr Houslař'
  },
  {
    src: '/img/gallery/7.JPG',
    caption: 'Premiéra promítání filmu Mistr Houslař'
  },
  {
    src: '/img/gallery/8.JPG',
    caption: 'Premiéra promítání filmu Mistr Houslař'
  },
  {
    src: '/img/gallery/9.JPG',
    caption: 'Premiéra promítání filmu Mistr Houslař'
  },

]
</script>
<style>
.gallery-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: white;
  height: auto;
  position: relative;
  background-color: #041415;
}
</style>