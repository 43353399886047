<template>
  <section class="trailer-section" id="trailer">
    <div class="side-text">
      <p>Trailer</p>
    </div>

    <div class="center-trailer">
      <div class="play-icon" ref="playIcon" :class="{ active: isInView }" @click="openTrailer">
        <i class="pi pi-play" data-aos="fade" data-aos-duration="3000" data-aos-delay="500" ></i>
      </div>

      <transition name="modal">
        <div v-if="showTrailer" class="modal">
          <div class="modal-content">
            <iframe src="https://www.youtube.com/embed/CjHh3Yzxi9c?si=RqUXs39-k-ccG2yd?autoplay=1"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    allow="autoplay"
                    allowfullscreen
            ></iframe>
            <button class="close-btn" @click="closeTrailer">Ukončit projekci >></button>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      showTrailer: false,
      isInView: false,
    };
  },
  methods: {
    openTrailer() {
      this.showTrailer = true;
    },
    closeTrailer() {
      this.showTrailer = false;
    },
    handleIntersection(entries) {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.isInView = true;
      } else {
        this.isInView = false;
      }
    },
  },
  mounted() {
    const observer = new IntersectionObserver(this.handleIntersection, {
      threshold: 0.5,
    });

    observer.observe(this.$refs.playIcon);
  },
};
</script>

<style scoped>
.trailer-section {
  position: relative;
  height: 700px;
  background-image: url('../../public/img/backgorund.png');
  background-size: cover;
  background-position: center;
  text-align: center;
}

.center-trailer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.play-icon {
  font-size: 4rem;
  cursor: pointer;
  color: #b0c7d1;
  transition: transform 0.3s ease;
}

.play-icon.active {
  animation: scaleAnimation 3s infinite ease-in-out;
}

@keyframes scaleAnimation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.13);
  }
}

.play-icon:hover {
  color: #ffffff;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 80%;
  height: 80%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  position: absolute;
  top: -50px;
  right: 10px;
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  color: white;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
}

.close-btn:hover {
  color: #b0c7d1;
}


.modal-enter-active, .modal-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.modal-enter, .modal-leave-to {
  opacity: 0;
  transform: scale(0.9);
}


@media (max-width: 1670px) {
.side-text {
  position: absolute;
  padding-top: 25px;
}
}
</style>
