<template>
  <section class="director-section">
    <div class="side-text">
      <p>{{ $t('about_director') }}</p>
    </div>
    <div class="director-center">
      <div class="director-text" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
        <div class="text-box">
          <p>
            {{ $t('miroslav_medal_about') }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DirectorComponent',
};
</script>

<style scoped>
.director-section {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 840px;
  overflow: hidden;
  background-image: url('../../public/img/miroslavmedal_director.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.director-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.director-text {
  max-width: 700px;
  padding: 2rem 4rem 2rem 4rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: -40%;
  margin-top: 10%;
}

@media (max-width: 1400px) {
  .director-text {
    margin-right: 0;
    margin-top: 50px;
    text-align: justify;
  }
}

@media (max-width: 768px) {
  .director-section {
    height: auto !important;
    background-attachment: scroll;
  }
  .director-text {
    background-color: transparent;
  }
  .side-text {
    opacity: 0.5;
  }
}

.text-box {
  margin-top: 20px;
}

.text-box p {
  font-size: 1.2rem;
  line-height: 1.8;
  opacity: 0.9;
}

@media (max-width: 768px) {
  .director-text {
    max-width: 100%;
    padding: 4rem 2rem;
    margin-right: 0%;
    height: 100%;
    margin-top: 0 !important;
    text-align: justify;
  }

  .text-box p {
    font-size: 1.1rem;
  }
}
</style>
